.footer
  color: white
  font-size: medium
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-wrap: wrap

.footer-logo
  width: 22%
  margin-left: 20px
  margin-right: 20px
  margin-top: 0
  margin-bottom: 0
  min-width: 240px
  text-align: center
  align-self: center

.footer-logo-img
  width: 256px
  padding: var(--padding) 0
