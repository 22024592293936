.entry-img
  width: 200px
  margin: auto
  overflow: hidden

  img
    border-radius: 10px
    width: auto
    max-height: 140px
    border: 2px solid $medium-blue
    padding: 2px

.markdown-page
  h1
    color: $medium-red
    font-size: 28px

  h2
    font-weight: 500 !important
    color: $medium-red
    font-size: 24px !important
    text-align: left !important
    margin: 50px 0 10px !important

  h3
    color: $medium-red
    font-size: 20px
    margin: 30px 0 10px !important

  h4
    color: $medium-red
    font-size: 18px
    margin: 20px 0 10px !important

  p
    text-align: left
    margin: 10px 0 10px !important

  ul
    list-style: square !important
    padding-left: 20px !important

  ol
    list-style: decimal !important
    padding-left: 20px !important

@media screen and (max-width: 1023px)
  .media
    flex-direction: column
    align-items: center

  .entry-date
    margin-bottom: 0.3rem !important
    margin-top: 0.5rem !important

  .media-content
    text-align: center

@media screen and (max-width: 768px)
  .share-buttons
    margin-top: 1.5rem
