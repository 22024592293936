@font-face
	font-family: couture-bld
	src: url(../fonts/couture-bld.otf)

$font-family_1: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
$font-family_2: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
$font-family_3: montserrat, helvetica neue, Arial, sans-serif, -apple-system
$font-family_4: couture-bld

body
	font-family: $font-family_3
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	background-image: url(../images/background.png)
	background-repeat: repeat
	background-size: auto
	box-shadow: inset 0 0 0 1000px #0006

code
	font-family: $font-family_2

.is-equal-height
	display: flex
	flex-direction: column
	height: 100%

.card-footer
	margin-top: auto

.spaced-fa-icon
	padding: 0 10px

.main-container
	min-height: 75vh

.footer
	min-height: 25vh

.breadcrumb-current
	margin-left: 0.75em
	color: white

@media screen and (max-width: 1023px)
	.navbar-start
		.active
			color: #ffffff
			background-color: $background-light

.election-box
	background-color: white !important
	border-radius: 10px
	color: black
	margin: 1rem auto
	max-width: 1024px
	padding: 0 2rem 2rem 2rem !important

@media screen and (max-width: 1023px)
	.field.is-grouped
		justify-content: center

@media screen and (max-width: 768px)
	.election-elements
		flex-direction: column

	.election-title
		text-align: center !important
		padding-right: 0 !important

	.election-buttons
		flex-direction: column !important

	.election-button
		margin-right: 0 !important
		margin: 0.2rem

	.election-box
		padding-left: 1rem !important
		padding-right: 1rem !important

	.entry-body-img
		width: 75vw !important

.election-buttons
	align-items: center

.election-title
	color: $dark-blue
	text-align: left
	text-transform: uppercase
	padding-right: 0.5rem

.unit-logo
	display: flex
	justify-content: center
	align-items: center
	padding: 0.5em
	padding-top: 1em

.elections-list li
	padding-top: 0.5em
	padding-bottom: 0.5em

.election-message
	text-align: center
	border: 2px solid $medium-blue

.has-text-red
	color: red

.election-closed
	color: red
	text-align: center

.election-button-vote
	background-color: $medium-blue !important
	color: white !important
	border-color: $medium-blue

.election-button-vote:hover
	background-color: $light-blue !important
	border-color: $medium-blue

.election-button-info
	background-color: $medium-red !important
	color: white !important
	border-color: $medium-red

.election-button-info:hover
	background-color: $light-red !important
	border-color: $medium-red

.is-transparent
	background: none

.election-stats-level
	background-color: $ultra-light-blue

.election-stat .heading
	color: $dark-blue
	font-size: 20px

.election-stat .title
	color: $dark-blue
	margin-top: 5px

.media-content h1
	font-weight: bold
	margin-bottom: 5px

.news-read-more
	color: white
	background-color: $medium-red
	border-color: $medium-red

.news-read-more:hover
	background-color: $light-red
	color: white
	border-color: $medium-red

.floating-icon
	background-color: $dark-blue
	border: 2px solid #fff
	border-radius: 10px
	bottom: 20px
	color: #fff
	cursor: pointer
	font-size: 18px
	outline: none
	padding: 15px
	position: fixed
	right: 30px
	z-index: 99

.entry-body-img
	margin-left: auto
	margin-right: auto
	width: 50%
	margin-top: 1em
	margin-bottom: 1em
	border: 2px solid $dark-blue

.navbar-item-social:hover
	background-color: $dark-blue !important
